<footer class="footer-area">
    <div class="container">
      
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i>2021 All Rights Reserved.</p>
    </div>
</footer>


<ngx-scrolltop></ngx-scrolltop>