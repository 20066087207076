<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">

        <img src="assets/img/logo.png" alt="image" class="navbar-brand" routerLink="/">
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item"><a href="https://dxperts.network " class="nav-link" target="_blank">
                 Home
                </a></li>
                <li class="nav-item"><a href="https://about.dxperts.network " class="nav-link" target="_blank">
                   About
                  </a></li>
                <li class="nav-item"><a href="https://grants.dxperts.network" class="nav-link" target="_blank">
                   Grants
                  </a></li>
                <li class="nav-item"> <a href="https://blockproducer.dxperts.network" class="nav-link" target="_blank">
                    Block Producer
                  </a></li>
                <li class="nav-item"><a href="https://ecosystem.dxperts.network" class="nav-link" target="_blank">
                 Echo System
                  </a></li>
                  <li class="nav-item"> <a href="https://dxpertscommunity.com" class="nav-link" target="_blank">
                      Community
                    </a></li>
                  <li class="nav-item"><a href="#" class="nav-link" target="_blank">
                    <div id="google_translate_element"></div>
                    <script type="text/javascript">
                      function googleTranslateElementInit() {
                        new google.translate.TranslateElement(
                          { pageLanguage: "en" },
                          "google_translate_element"
                        );
                      }
                    </script>
                    <script
                      type="text/javascript"
                      src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                    ></script>

                     </a></li>


            </ul>
           <ul class="navbar-nav ml-auto for-responsive">
             <li class="nav-item"><a href="https://dxperts.network " class="nav-link" target="_blank">
                Home
               </a></li>
               <li class="nav-item"><a href="https://about.dxperts.network " class="nav-link" target="_blank">
                  About
                 </a></li>
               <li class="nav-item"><a href="https://grants.dxperts.network" class="nav-link" target="_blank">
                  Grants
                 </a></li>
               <li class="nav-item"> <a href="https://blockproducer.dxperts.network" class="nav-link" target="_blank">
                   Block Producer
                 </a></li>
               <li class="nav-item"><a href="https://ecosystem.dxperts.network" class="nav-link" target="_blank">
                Echo System
                 </a></li>
                 <li class="nav-item"> <a href="https://dxpertscommunity.com" class="nav-link" target="_blank">
                     Community
                   </a></li>

            </ul>
        </div>
    </div>
</nav>
