<div class="home-slides"><div style="position: absolute;
    color: #000e1a;
    z-index: 9999;
    top: 80%;
    left: 35%;">  <ul class="footmenu">

                <li ><a href="https://telegram.me/share/url?&url=http://dxperts.org" class="nav-link" target="_blank">
                    <i class="fab fa-telegram"></i>
                  </a></li>
                <li ><a href="#" class="nav-link" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                  </a></li>
                <li ><a href="https://twitter.com/" class="nav-link" target="_blank">
                  <i class="fab fa-twitter"></i>
                  </a></li>
                     <li ><a href="https://twitter.com/" class="nav-link" target="_blank">
                  <i class="fab fa-reddit"></i>
                  </a></li>
                  <li ><a href="#" class="nav-link" target="_blank">
                    <i class="fas fa-rss"></i>

                    </a></li>

            </ul></div>
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner">
                <video loop muted autoplay class="video-background">
                    <source src="assets/video/video2.mp4" type="video/mp4">
                </video>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h1><span>DXPERTS</span> COMMUNITY</h1>
                                        <h4>DLT Experts Professional Network</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner">
                    <video loop muted autoplay class="video-background">
                        <source src="assets/video/Tropical.mp4" type="video/mp4">
                    </video>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-text">
                                    <h1><span>DXPERTS</span> COMMUNITY SERVICES</h1>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-6 text-center">
                                                <div class="mt-5" style="margin-top:0px !important;">
                                                    <i class="fas fa-4x fa-gem text-primary mb-4 fh" style="font-weight: 600 !important;font-size: 2.5em !important;"></i>
                                                    <h3 class="h6 mb-2">Membership</h3>
                                                    <p class="text-muted mb-0">Become a Community Member</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 text-center">
                                                <div class="mt-5" style="margin-top:0px !important;">
                                                    <i class="fas fa-4x fa-users text-primary mb-4 fh" style="font-weight: 600 !important;font-size: 2.5em !important;"></i>
                                                    <h3 class="h6 mb-2">Network</h3>
                                                    <p class="text-muted mb-0">Develop Connections & Contacts</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 text-center">
                                                <div class="mt-5" style="margin-top:0px !important;">
                                                    <i class="fas fa-4x fa-globe text-primary mb-4 fh" style="font-weight: 600 !important;font-size: 2.5em !important;"></i>
                                                    <h3 class="h6 mb-2">Ambassador</h3>
                                                    <p class="text-muted mb-0">Represent Regional states</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 text-center">
                                                <div class="mt-5" style="margin-top:0px !important;">
                                                    <i class="fas fa-4x fa-heart text-primary mb-4 fh" style="font-weight: 600 !important;font-size: 2.5em !important;"></i>
                                                    <h3 class="h6 mb-2">Spread Peace & Love</h3>
                                                    <p class="text-muted mb-0">connect the dots of Humanity</p>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner">
                      <video loop muted autoplay class="video-background">
                        <source src="assets/video/video3.mp4" type="video/mp4">
                    </video>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-text">
                                    <h1><span>DXPERTS</span></h1>
                                    <h4>ACCESS TO RESOURCES</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner">
                      <video loop muted autoplay class="video-background">
                        <source src="assets/video/promo-video.mp4" type="video/mp4">
                    </video>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-text">
                                    <h1><span>DXPERTS</span></h1>
                                            <h4>NETWORKING & EVENTS</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner">
                    <video loop muted autoplay class="video-background">
                        <source src="assets/video/video2.mp4" type="video/mp4">
                    </video>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-text">
                                    <h1>Explore <span>DXPERTS</span> Community</h1>
                                            <h4>Ready to develop your contacts via networking ? start/promote your next project with us? send us an email and we will get back to you as soon as possible!</h4>
                                            <br/><a href="https://dxperts.org" target="_blank" class="btn btn-primary">JOIN FOR FREE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="main-banner">
                    <video loop muted autoplay class="video-background">
                        <source src="assets/video/video3.mp4" type="video/mp4">
                    </video>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-text">
                                    <h1>Core Members of <span>DXPERTS</span> Community!</h1>
                                            <br/><a href="https://dxpertscommunity.com/" target="_blank" class="btn btn-primary">EXPLORE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
<ng-template carouselSlide>
    <div class="main-banner">
        <video loop muted autoplay poster="#" class="image-background">

        </video>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-text">
                        <h1>Core Members of <span>DXPERTS</span> Community!</h1>
                                <br/><a href="https://dxpertscommunity.com/" target="_blank" class="btn btn-primary">EXPLORE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</ng-template>
</owl-carousel-o>

</div>
